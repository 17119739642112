<template>
  <div class="qimiaoHuman">
    <!--定义Vue组件的模板内容-->
    <!--    <div class="fishCss"></div>-->
    <div class="fishCss fishActive"></div>
  </div>
</template>

<script>
// import { mapState } from 'vuex' // 从store中导入【全局】数据
// 定义Vue组件的业务逻辑
export default {
  // 为组件命名
  // name:'',
  // 加载子目录组件👇
  // components: {
  // } ,
  // 如果作为子组件向父组件传递
  props: {
    // xxx: { // xxx 引导子组件向父组件👆传值的要素,父组件里要有定义
    //   type: Object,
    //   required: true
    // }
  },

  // 私有数据
  data() {
    return {}
  },
  // 创建生命周期函数,立即获取所有元素
  // 当页面刚加载的时候会执行的钩子函数
  created() {},
  // 处理函数
  methods: {},

  // 将【全局】数据,映射为当前组件的计算属性
  computed: {
    // ...mapState([''])
  },

  // ...其他业务逻辑
}
</script>

<style lang="less" scoped>
/**这个语法中支持less scoped只在当前组建生效，没有则全局生效 */
/**定义组件的形式 */
.qimiaoHuman {
  position: relative;
  .fishCss {
    position: absolute;
    top: 0%;
    left: 50%;
    transform: translate(-50%, -0%);
    transform: scale(.8);
    width: 178px;
    height: 178px;
    // background-image: url("../../assets/images/login/humanMove.png");
    /* background-position:0 -920px; */
    animation: fishCss 1s steps(10) infinite;
    transform-origin: 0 0; /**如果位置不以正中心为例，那么就要先转换锚点位置为左上角。 */
    /**调整大小通过缩放**/
  }

  .fishCss {
    animation-play-state: paused;
  }

  .fishCss:hover {
    animation-play-state: running;
  }

  @keyframes fishCss {
    from {
      background-position: 0px 0px;
    }
    to {
      background-position: 0px -1870px;
    }
  }

  .fishActive {
    animation-play-state: running;
  }
}
</style>
