<template>
	<div class="box">
		<languages v-if="!inputPassword"></languages>
		<!-- <div class="moveHuman ">
		<qimiaoHuman class="logo move"></qimiaoHuman></div> -->
		<img class="logo" src="../../../public/loginLogo.png" />
		<h1 class="welcome">{{ $t('创建新用户') }}</h1>
		<div class="input-box">
			<!-- 邮箱 -->
			<label v-show="emailActive" :class="{active:!emailActive}"
				:style="emailMsg?'color:#d00e17':''">{{ (!emailPlaceholder ? '' : emailPlaceholder === 1 ? $t('邮箱') : $t('手机')) || $t('电话或邮箱') }}</label>
			<input name="username" @focus="emailActive = true" @blur="emailBlur()" @keyup.enter="next()"
				v-model="userEmail"
				:placeholder="emailActive ? '' : (!emailPlaceholder ? '' : emailPlaceholder === 1 ? $t('邮箱') : $t('手机')) || $t('电话或邮箱')"
				:style="emailMsg?'border: 1px solid #d00e17;':''" />
			<span class="error" v-if="emailRet === 5"><i class="iconfont icon-error"></i>{{ $t('电话号码不能为空') }}</span>
			<span class="error" v-if="emailRet === 5.5"><i class="iconfont icon-error"></i>{{ $t('邮箱不能为空') }}</span>
			<span class="error" v-if="emailRet === 4"><i class="iconfont icon-error"></i>{{ $t('请输入有效的11位手机号码') }}</span>
			<span class="error" v-if="emailRet === 4.5"><i class="iconfont icon-error"></i>{{ $t('请输入有效的电子邮件地址') }}</span>
			<span class="error" v-if="emailRet === 3"><i class="iconfont icon-error"></i>{{ $t('请输入合规的邮箱地址或11位大陆电话号码') }}</span>
			<span class="error" v-if="emailRet === 2"><i class="iconfont icon-error"></i>{{ $t('用户已经存在') }}</span>
			<span class="error" v-if="emailRet === 1"><i class="iconfont icon-error"></i>{{ $t('验证码已发送请勿重复获取') }}</span>
			<span class="error" v-if="emailRet === -1"><i class="iconfont icon-error"></i>{{ $t('短信发送失败') }}</span>
		</div>

		<template v-if="inputPassword">
			<div class="input-box">
				<label class="verify-label" v-show="validateActive"
					:class="{active:!validateActive}">{{ $t('验证码') }}</label>
				<input name="validate" @focus="validateActive = true" @blur="validateBlur()" v-model="validateCode"
					:placeholder="validateActive?'':this.$t('验证码')" :maxlength="sendTest === $t('发送至邮箱')? 4 : 6" />
				<span class="error" v-if="validateMsg != ''"><i class="iconfont icon-error"></i>{{validateMsg}}</span>
				<!-- 发送验证码 -->
				<button @click="sendEmail()" v-if="send_seconds == 0" class="send">{{ sendTest }}</button>
				<span class="email-timer" v-else>{{sendEmailTip}}</span>
			</div>
			<div class="input-box">
				<label class="pass-label" v-show="passActive" :class="{active:!passActive}">{{$t('密码')}}</label>
				<input name="password" :type="showPassword?'text':'password'" @focus="passActive = true"
					@blur="passBlur()" v-model="password" :placeholder="passActive?'':this.$t('密码')" />
				<span @click="togglePassword()" v-if="!showPassword" class="glyphicon glyphicon-eye-open"></span>
				<span @click="togglePassword()" v-else class="glyphicon glyphicon-eye-close"></span>
				<span class="error" v-if="passMsg != ''"><i class="iconfont icon-error"></i>{{passMsg}}</span>
			</div>
			<div class="input-box">
				<label class="confirm-label" v-show="confirmActive"
					:class="{active:!confirmActive}">{{$t('确认密码')}}</label>
				<input name="password" :type="showConfirmPassword?'text':'password'" @focus="confirmActive = true"
					@blur="confirmPassBlur()" v-model="confirmPass" :placeholder="confirmActive?'':this.$t('确认密码')" />
				<span @click="toggleConfirmPassword()" v-if="!showConfirmPassword"
					class="glyphicon glyphicon-eye-open"></span>
				<span @click="toggleConfirmPassword()" v-else class="glyphicon glyphicon-eye-close"></span>
				<span class="error" v-if="confirmMsg != ''"><i class="iconfont icon-error"></i>{{confirmMsg}}</span>
			</div>
			<!-- <div class="input-box">
        <label class="pass-label" v-show="codeActive" :class="{active:!codeActive}">Invite Code</label>
        <input name="invite" @focus="codeActive = true" @blur="codeBlur()" v-model="inviteCode"
          :placeholder="codeActive?'':'Invite Code'" />
        <span class="error" v-if="codeMsg != ''"><i class="iconfont icon-error"></i>{{codeMsg}}</span>
      </div> -->

		</template>

		<button class="continue" @click="next()">{{$t("确定")}}</button>
		<span class="log-in">{{ $t('已经有账户了') }} ? <span @click="toLogin()">{{ $t('登录') }}</span> </span>
	</div>
</template>
<script>
	import {
		checkUser,
		userRegister,
		sendEmail,
		sendPhone
	} from '../../config/api.js'
	import languages from "../../components/languages/index.vue"
	import qimiaoHuman from "../../components/flash/qimiaoHuman.vue"
	export default {
		name: 'RegisterView',
		components: {
			languages,
			qimiaoHuman
		},
		data() {
			return {
				emailActive: false,
				passActive: false,
				confirmActive: false,
				codeActive: false,
				validateActive: false,
				emailMsg: '',
				emailRet: null,
				emailPlaceholder: null,
				passMsg: '',
				codeMsg: '',
				validateMsg: '',
				confirmMsg: '',
				userEmail: '',
				password: '',
				confirmPass: '',
				inviteCode: '',
				validateCode: '',
				showPassword: false,
				showConfirmPassword: false,
				checkUserOrNot: false,
				inputPassword: false,
				send_seconds: 0,
				sendTest: this.$t('发送至邮箱'),
				sendEmailTip: '',
				sendEmailTimer: null,

			}
		},
		mounted() {

		},
		methods: {
			emailBlur() {
				if (this.userEmail == '') {
					this.emailActive = false
				}
			},
			passBlur() {
				if (this.password == '') {
					this.passActive = false
				}
			},
			codeBlur() {
				if (this.inviteCode == '') {
					this.codeActive = false
				}
			},
			validateBlur() {
				if (this.validateCode == '') {
					this.validateActive = false
				}
			},
			confirmPassBlur() {
				if (this.confirmPass == '') {
					this.confirmActive = false
				}
			},
			togglePassword() {
				this.showPassword = !this.showPassword;
			},
			toggleConfirmPassword() {
				this.showConfirmPassword = !this.showConfirmPassword;
			},
			sendEmail() {
      	this.emailRet = null
				this.validateMsg = ''
				if (this.emailPlaceholder === 1) {
					if (!this.userEmail) return this.emailRet = 5.5
					let that = this

					that.emailMsg = ''
					that.emailRet = 0
					that.send_seconds = 59;
					that.sendEmailTip = `${that.$t('在')} ${that.send_seconds} ${that.$t('秒后重试')}`
					that.sendEmailTimer = setInterval(function() {
						if (that.send_seconds > 0) {
							that.send_seconds -= 1;
							that.sendEmailTip = `${that.$t('在')} ${that.send_seconds} ${that.$t('秒后重试')}`
						} else {
							clearInterval(that.sendEmailTimer)
						}
					}, 1000)

					sendEmail({
						"type": "register",
						"email": this.userEmail
					}).then(res => {
						if (res.data["emailMsg"] !== '') {
							that.emailMsg = res.data["emailMsg"]
							that.send_seconds = 0
							clearInterval(that.sendEmailTimer)
							if (res.data["emailMsg"] === 'Invalid email format') {
								that.emailRet = 4.5
							}
						}
					})
				}
				if (this.emailPlaceholder === 2) {
					if (!this.userEmail) return this.emailRet = 5
					const phoneRegex = /^\d{11}$/;
					if (!phoneRegex.test(this.userEmail)) {
						this.emailRet = 4;
						// return (this.emailMsg = this.$t("请输入有效的11位手机号码"));
					}
					if (!this.userEmail) {
						this.emailRet = 5;
						// return (this.emailMsg = this.$t("电话号码不能为空"));
					}
					this.emailMsg = "";
					let that = this;

					that.send_seconds = 59;
					that.sendEmailTip = `${that.$t("在")} ${that.send_seconds} ${that.$t(
            "秒后重试"
          )}`;
					that.sendEmailTimer = setInterval(function() {
						if (that.send_seconds > 0) {
							that.send_seconds -= 1;
							that.sendEmailTip = `${that.$t("在")} ${that.send_seconds} ${that.$t(
                "秒后重试"
              )}`;
						} else {
							clearInterval(that.sendEmailTimer);
						}
					}, 1000);

					sendPhone({
						phone: that.userEmail
					}).then(res => {
						if (res.data["ret"] === -1) {
							that.emailRet = res.data["ret"];
							return (that.emailMsg = this.$t("短信发送失败"));
						}
						if (res.data["ret"] === 1) {
							that.emailRet = res.data["ret"];
							return (that.emailMsg = this.$t("验证码已发送请勿重复获取"));
						}
					});
				}
			},
			next() {
				let that = this
				that.emailMsg = '';
				that.passMsg = '';
				if (!that.userEmail) {
					if (that.emailPlaceholder === 1) return that.emailRet = 5.5; // 邮箱
					if (that.emailPlaceholder === 2) return that.emailRet = 5; // 电话
					// return (this.emailMsg = this.$t("电话号码不能为空"));
				}
				if (!this.emailPlaceholder) { // 注册号格式验证
					checkUser({
						"username": this.userEmail
					}).then(res => {
						that.checkUserOrNot = res.data["checkOrNot"]
						/**regMethod为注册格式 -1为不合规 1为邮箱 2为手机  */
						if (res.data["regMethod"] === -1) {
							that.emailMsg = that.$t('填写格式有误');
							that.emailRet = 3;
							return
						}
						if (res.data["regMethod"] === 1) {
							that.emailPlaceholder = res.data["regMethod"]
						}
						if (res.data["regMethod"] === 2) {
							that.emailPlaceholder = res.data["regMethod"]
						}
						if (that.checkUserOrNot) {
							that.emailMsg = that.$t('用户已经存在');
							that.emailRet = 2
							that.emailActive = true
							that.emailPlaceholder = false
						} else {
							that.emailMsg = ''
							that.emailRet = null
							that.inputPassword = true
						}
					})
				} else {
					that.emailMsg = ""
					that.passMsg = ""
					that.codeMsg = ""
					that.validateMsg = ""
					that.confirmMsg = ""

					userRegister({
						"username": this.userEmail,
						"password": this.password,
						"confirmPass": this.confirmPass,
						// "inviteCode": this.inviteCode,
						"validateCode": this.validateCode,
						"type": this.emailPlaceholder, // 1是邮箱，2是电话
					}).then(res => {
						console.log(res.data["accountMsg"])
						that.emailMsg = ""
						that.passMsg = ""
						that.codeMsg = ""
						that.validateMsg = ""
						that.confirmMsg = ""
						that.emailRet = 0
						if (res.data["accountMsg"] == 'Please enter a valid email address') {
							console.log(res.data["accountMsg"] == 'Invalid username format')
							that.emailMsg = that.$t('请输入有效的电子邮件地址')
							that.emailRet = 4.5
						} 
						else if (res.data["accountMsg"] == 'Please enter a valid 11-digit mobile number') {

							that.emailMsg = that.$t('请输入有效的11位手机号码')
							that.emailRet = 4
						} else if (res.data["passMsg"] !== '') {
							if (res.data["passMsg"] == 'The password length cannot be less than 8 characters') {
								that.passMsg = this.$t('密码长度不超过8位')
							}
							if (res.data["passMsg"] == 'Please enter password') {
								that.passMsg = this.$t('请输入密码')
							}
							if (res.data["passMsg"] == 'The password must contain at least two combinations of letters and numbers, and not less than 8 characters') {
                return	that.passMsg = that.$t('密码至少包含字母和数字的两种组合且不小于8个字符')
							}
							if (res.data["passMsg"] == 'The password cannot contain spaces') {
								that.passMsg = this.$t('密码不能包含空格')
							}							
							if (res.data["confirmMsg"] == 'Please enter password') {
								that.passMsg = this.$t('请确认密码')
							}
							if (res.data["confirmMsg"] == 'The two password inputs are inconsistent') {
								that.passMsg = this.$t('两次密码输入不一致')
							}
							// } else if (res.data["codeMsg"] !== '') {
							// that.codeMsg = res.data["codeMsg"]
						} else if (res.data["validateMsg"] !== '') {
							if (res.data["validateMsg"] == 'The validate code is incorrect') {
								that.validateMsg = that.$t('验证码有误')
							}
							if (res.data["validateMsg"] == 'Please enter the validate code') {
								that.validateMsg = that.$t('请输入验证码')
							}
						} else if (res.data["confirmMsg"] == 'Please enter the confirm password') {
							that.confirmMsg = that.$t('请输入确认密码')
						} else if (res.data["confirmMsg"] == 'The two password inputs are inconsistent') {
							that.confirmMsg = that.$t('两次密码输入不一致')
						} else if (res.data["confirmMsg"] == 'The password cannot be a pure number') {
							that.confirmMsg = that.$t('密码不能为纯数字')
						} else {
							that.$nextTick(() => {
								alert("注册成功")
								that.$router.push({
									name: 'login'
								})
							})
						}
					})
				}
			},
			toLogin() {
				this.$router.replace({
					name: 'login'
				})
			}
		},
		watch: {
			emailPlaceholder(newVal) { // newVal :1 为电子邮箱； 2 为电话
				// console.log(newVal)
				if (newVal === 1) return this.sendTest = this.$t('发送至邮箱')
				if (newVal === 2) return this.sendTest = this.$t('获取验证码')
			}
		}
	}
</script>
<style lang="less" scoped>
	.box {
		width: 100%;
		padding: 0;
		margin: 0;
		text-align: center;
		padding: 8% 0;


		.logo {
			width: 100px;
			height: 100px;
			display: block;
			margin: 1% auto 4rem auto;
			border-radius: 12%;
			border: 3px solid #10a37f;
		}

		.moveHuman {
			width: 25%;
			position: relative;
			text-align: left;
			margin: auto;

			.move {
				position: absolute;
				top: 0;
				left: 0;
				animation: move 5s infinite linear;
			}

			@keyframes move {
				0% {
					opacity: 0;
					left: calc(0% - 85px);
				}

				10% {
					opacity: 1;
					left: calc(0% - 85px);
				}

				90% {
					opacity: 1;
					left: calc(100% - 150px);
				}

				100% {
					opacity: 0;
					left: calc(100% - 150px);
				}
			}
		}

		.welcome {
			font-size: 28px;
		}

		.input-box {
			width: 25%;
			margin: 3rem auto;
			position: relative;
			text-align: left;

			label {
				display: block;
				color: #10a37f;
				position: absolute;
				margin-left: 20%;
				margin-top: 1rem;
				padding: 0 .5rem;
				position: absolute;
				transform: translate(-50%, -150%);
				transition: all .3s ease-in-out;
				transition-delay: .1s;
				background-color: white;
				;
			}

			label.active {
				top: -2rem;
				left: 2%;
				transform: translate(0, -20%) scale(0.8);
			}

			.pass-label {
				margin-left: 15%;
			}

			.confirm-label {
				margin-left: 15%;
			}

			.verify-label {
				margin-left: 15%;
			}

			input {
				height: 52px;
				width: 100%;
				border: 1px solid #10a37f;
				border-radius: 3px;
				padding: 0 10px;
				font-size: 14px;

				&:-webkit-autofill,
				textarea:-webkit-autofill,
				select:-webkit-autofill {

					-webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
					background-color: transparent;
					background-image: none;
					transition: background-color 50000s ease-in-out 0s; //背景色透明 生效时长 过渡效果 启用时延迟的时间
				}
			}

			input:focus {
				outline: none;
				border: 1px solid #10a37f;
			}

			input:visited {
				background-color: #000;
			}

			input::-webkit-input-placeholder {
				margin-left: 10%;
				text-indent: 20px;
			}

			.glyphicon-eye-open,
			.glyphicon-eye-close {
				position: absolute;
				right: 0;
				top: 2rem;
				right: 5%;
			}

			.glyphicon-eye-open:hover,
			.glyphicon-eye-close:hover {
				cursor: pointer;
			}

			.error {
				color: #d00e17;
				font-size: 12px;
				display: inline-block;
				margin-top: .5rem;

				.icon-error {
					vertical-align: middle;
					margin-right: .5rem;
				}
			}

			.forget {
				color: #10a37f;
				margin-top: 1rem;
				display: block;
			}

			.forget:hover {
				cursor: pointer;
			}

			a {
				float: right;
				color: #10a37f;
				text-decoration: underline;
			}

			a:hover {
				cursor: pointer;
			}

			.send {
				background-color: #10a37f;
				color: white;
				outline: none;
				border: none;
				border-radius: 3px;
				position: absolute;
				right: 8px;
				top: 8px;
				height: 36px;
			}

			.email-timer {
				background-color: white;
				color: #10a37f;
				border: 1px solid #10a37f;
				border-radius: 3px;
				position: absolute;
				padding: .5rem;
				right: 12px;
				top: 12px;
				font-size: 12px;
			}
		}

		.continue {
			background-color: #10a37f;
			color: white;
			border: 0px;
			outline: none;
			border-radius: 3px;
			height: 52px;
			width: 25%;
			margin-bottom: 3rem;
		}

		.log-in {
			display: block;
			font-size: 14px;

			span {
				color: #10a37f;
			}

			span:hover {
				cursor: pointer;
			}
		}
	}

	@media screen and (max-width: 768px) {

		.input-box,
		.continue,
		.moveHuman {
			width: 80% !important;
		}

		.box {
			.logo {
				margin-top: 5rem;
			}
		}
	}
</style>